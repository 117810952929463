/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  function setupInfiniteScroll() {
    var $alm, $root, columns, columnCount;

    function getColumnCount() {
      var cols = 1;
      if (window.innerWidth >= 640) {
        cols = 3;
        if (window.innerWidth >= 1024) {
          cols = 4;
        }
      }
      return cols;
    }

    function createColumnsAndResort(cols) {
      var $posts = $root.find('article').detach();
      // console.log("createColumnsAndResort", cols, "removing posts", $posts);
      $root.empty();
      columns = [];
      for (var i = 0; i < cols; i++) {
        var $col = $('<div>').addClass('column').css({padding: 0, width: (100/cols)+'%'});
        $col.appendTo($root);
        columns.push($col);
        // console.log(columns, i);
      }
      $posts.each(function(i) {
        $(this).appendTo(columns[i % (columns.length)]);
      });
    }

    $alm = $('#ajax-load-more');
    $root = $('<div>').addClass('row').insertBefore($alm);
    columns = [];
    columnCount = getColumnCount();
    createColumnsAndResort(columnCount);
    // console.log("init");

    $(window).on('resize', function () {
      var newColumnCount = getColumnCount();
      if (columnCount !== newColumnCount) {
        // console.log("count changed from", columnCount, "to", newColumnCount, "at width", window.innerWidth);
        columnCount = newColumnCount;
        createColumnsAndResort(columnCount);
      }
    });

    $.fn.almComplete = function (alm) {
      // console.log("More posts loaded");
      for (var i = 0; i < alm.data.length; i++) {
        var $col = columns[i % (columns.length)];
        $col.append(alm.data[i]);
      }
    };
  }


  // Remove host from templateURL injected by PHP (use relative URLs)
  WELocal.templateUrl = WELocal.templateUrl.replace(/^(https?\:)?\/\/([\w\.\-\_]+)(\:\d+)?\/*/, '/');


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var WELocalRouter = {
    // All pages
    'common': {
      init: function () {
        $('a').smoothScroll({offset: -100});
        $(window).on('load', function () {
          $(document).foundation();
          $(window).trigger('resize'); //forces some things to relayout, for ex. fixes #93
        });
        $("#ajax-load-more").each(function () {
          $(this).data('alm').scroll_distance = -100;
        });
        $('#local-nav-toggle').click(function (e) {
          $(window).scrollTop(0);
          var show = $('body').toggleClass('local-nav-open').hasClass('local-nav-open');
          var $menu = $('#nav-local');
          if (show && window.innerWidth < 760) {
            $menu.foundation('open', e, e.target);
          } else {
            $menu.foundation('close');
          }
        });
        $('#nav-toggle').click(function () {
          $(window).scrollTop(0);
        });

        setupInfiniteScroll();

        $(window).on('resize', function () {
          $('.local-product-carousel .carousel').each(function () {
            var $component = $(this);
            var $tiles = $component.find('.product');
            var totalWidth = ($tiles.length) * ($tiles.first().width() + 25);
            var shouldEnable = totalWidth > window.innerWidth;
            var isEnabled = $component.hasClass('flickity-enabled');
            if (isEnabled === shouldEnable) {
              return;
            }
            if (shouldEnable) {
              $component.flickity({
                cellAlign: 'left',
                wrapAround: true,
                percentPosition: false,
                freeScroll: true,
                prevNextButtons: false,
                pageDots: false
              });
            }
            else {
              $component.flickity('destroy');
              $('.local-product-carousel').find('.product').removeAttr('style');
            }
            $component.toggleClass('enabled', shouldEnable);
          });
        }).trigger('resize');
        $('.local-article-carousel .carousel').flickity({
          percentPosition: true,
          contain: true,
          wrapAround: true,
          pageDots: false,
          imagesLoaded: true
        });
        $('.local-shop-the-look').imagesLoaded(function () {
          var $component = $('.local-shop-the-look .carousel');
          var opts = {
            cellAlign: 'left',
            wrapAround: true,
            percentPosition: true,
            freeScroll: false,
            prevNextButtons: true,
            pageDots: false
          };
          var $slides = $component.find('.product');
          if ($slides.length < 4){
            var totalWidth = 100 / ($slides.length);
            var width = 'calc(' + totalWidth + '% - 24px)';
            $slides.width(width);
            opts.wrapAround = false;
            opts.contain = true;
            opts.prevNextButtons = false;
          }
          $component.flickity(opts);
        });
        $('.local-maker-grid').imagesLoaded(function () {
          var $component = $('.local-maker-grid');
          var $equalizerContainer = $component.find('.equalizer');
          var $contentRow = $component.find('.content-row');
          var $expanderRow = $component.find('.expander-row');
          var maxRows = parseInt($component.data('maxRows'));
          if (maxRows === 0) {
            // don't collapse when maxRows set to 0
            return;
          }
          var equalizer = new Foundation.Equalizer($equalizerContainer);
          var intrinsicHeight = $contentRow.height();
          var maxHeight = intrinsicHeight;
          var toggleCollapse = function () {
            //console.log("maker grid toggle, collapsed height=", maxHeight, "intrinsic height=", intrinsicHeight);
            isCollapsed = $component.toggleClass('collapsed').hasClass('collapsed');
            TweenLite.to($contentRow, 0.5, {height: (isCollapsed ? maxHeight : intrinsicHeight)});
          };
          $expanderRow.click(toggleCollapse);
          $equalizerContainer.on('postequalized.zf.equalizer', function (e) {
            $contentRow.css('height', '');
            intrinsicHeight = $contentRow.height();
            var rowHeight = $contentRow.find('.maker-tile').height();
            maxHeight = (rowHeight + 25) * maxRows;
            if (maxHeight > intrinsicHeight - rowHeight) {
              $expanderRow.hide();
              return;
            }
            $expanderRow.show();
            var isCollapsed = $component.hasClass('collapsed');
            $contentRow.css('height', (isCollapsed ? maxHeight : intrinsicHeight));
            //console.log("maker grid resize, collapsed height=", maxHeight, "intrinsic height=", intrinsicHeight);
          }).trigger('postequalized.zf.equalizer');
          var $tiles = $contentRow.find('.maker-tile');
          var count = $tiles.length;
          if (count < 4) {
            $contentRow.addClass('single-row');
            $expanderRow.remove();
          } else {
            $contentRow.imagesLoaded(toggleCollapse);
          }
        });
        $('.header--map').each(function () {
          // Add pins to map
          var $map = $(this);
          var url = WELocal.templateUrl + '/local.json';
          var local = JSON.parse(WELocal.localMapJson);
          $.each(local.hubs, function (i, hub) {
            $('<a class="pin"></a>').attr(hub).css(hub).appendTo($map);
          });
        });
        $('main').magnificPopup({
          delegate: 'a.youtube-lightbox',
          type: 'iframe'
        });

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'page_template_template_local_home': {
      init: function () {
        var $hero = $('.home--hero');
        $hero.magnificPopup({
          delegate: 'a',
          type: 'iframe'
        });
        $hero.find('a').prepend('<i class="fa fa-play-circle"></i>');

        $('.header--map').click(function () {
          if (window.innerWidth < 760) {
            $('#nav-local').foundation('toggle');
          }
        });
      },
      finalize: function () {
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = WELocalRouter;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };


  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
